<template>
  <div>
    <div class="bg-white">
      <!-- <div class="padding stu-info"><border-title titleName="新建排课" /></div> -->
      <el-tabs v-model="activeName" type="border-card" style="height: 89vh;">
        <!-- 班级排课 -->
        <el-tab-pane v-if="detail.arrange_type == 0" label="编辑班级排课" name="first">
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
            <el-form-item label="班级/一对一" prop="className">
              <el-select :disabled="classDisabled" filterable v-model="ruleForm.className" placeholder="请选择班级/一对一" :remote-method="getClassList" @change="classChange">
                <el-option value="1" disabled class="border-bottom-grey">
                  <div class="flex justify-content-between" style="color: #000000; font-weight: 600;">
                    <div style="min-width: 250px;">班级名称</div>
                    <div style="width: 80px;">班主任</div>
                    <div style="width: 120px;">开班时间</div>
                    <div style="width: 80px;">人数</div>
                    <div style="width: 120px;">上课时间</div>
                  </div>
                </el-option>
                <el-option v-for="item in classList" :key="item.id" :label="item.class_name" :value="item.id" class="border-bottom-grey">
                  <div class="flex justify-content-between">
                    <div style="min-width: 250px;">{{ item.class_name }}</div>
                    <div style="width: 80px;">{{ item.teacher_name }}</div>
                    <div style="width: 120px;">{{ item.starttime ? item.starttime.slice(0, 10) : '' }}</div>
                    <div style="width: 80px;">{{ item.class_num }}</div>
                    <div style="width: 120px;">{{ item.to_class_time ? item.to_class_time.slice(0, 10) : '' }}</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="排课方式" prop="schedulingMethod">
              <el-radio v-model="ruleForm.schedulingMethod" label="0">自由排课</el-radio>
              <!-- <el-radio v-model="ruleForm.schedulingMethod" label="1">重复排课</el-radio> -->
            </el-form-item>

            <el-form-item label="上课日期" prop="ClassDate">
              <el-date-picker value-format="yyyy-MM-dd" type="dates" v-model="ruleForm.ClassDate" placeholder="选择日期" :onPick="dateChange"></el-date-picker>
            </el-form-item>

            <el-form-item label="上课时段" prop="ClassTime">
              <!-- <el-select v-model="ruleForm.ClassTime" placeholder="请选择时间段" :disabled="ruleForm.ClassDate == '' || ruleForm.className == ''" v-if="shows"></el-select> -->

              <el-time-picker
                :disabled="ruleForm.ClassDate == '' || ruleForm.className == ''"
                is-range
                value-format="HH:mm"
                format="HH:mm"
                :picker-options="{ selectableRange: '07:00:00 - 22:00:00' }"
                type="datetime"
                v-model="ruleForm.ClassTime"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                style="width: 220px"
              ></el-time-picker>
              <!-- <label style="margin-left: 10px;" @click="changeTime">{{ text }}</label> -->
            </el-form-item>

            <el-form-item label="上课教师" prop="ClassTeacher">
              <el-select
                v-model="ruleForm.ClassTeacher"
                filterable
                placeholder="请选择上课教师"
                :disabled="ruleForm.ClassDate == '' || ruleForm.className == ''"
                :remote-method="getClassroomList"
                @change="teacherChange"
              >
                <el-option value="1" disabled class="border-bottom-grey">
                  <div class="flex justify-content-between" style="color: #000000; font-weight: 600;">
                    <div style="width: 120px;">姓名</div>
                    <div style="width: 80px;">性别</div>
                    <div style="width: 120px;">联系方式</div>
                    <div style="width: 80px;">冲突状态</div>
                    <div style="width: 170px;">上课校区</div>
                    <div style="width: 120px;">擅长科目</div>
                    <div style="width: 120px;">教师等级</div>
                    <div style="width: 120px;">时段</div>
                  </div>
                </el-option>
                <el-option v-for="(item, index) in teacherList" :key="index" :label="item.full_name" :value="item.id" class="border-bottom-grey">
                  <div class="flex justify-content-between">
                    <div style="width: 120px;">{{ item.full_name }}</div>
                    <div style="width: 80px;">{{ item.sex == 0 ? '女' : '男' }}</div>
                    <div style="width: 120px;">{{ item.phone }}</div>
                    <div style="width: 80px;">{{ item.ConflictState }}</div>
                    <div style="width: 170px;">{{ item.class_schools }}</div>
                    <div style="width: 120px;">{{ item.good_subjects }}</div>
                    <div style="width: 120px;">{{ item.teacher_grade }}</div>
                    <!-- <label style="width: 120px;">查看</label> -->
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上课教室" prop="Classroom">
              <el-select
                v-model="ruleForm.Classroom"
                placeholder="请选择上课教室"
                filterable
                :disabled="ruleForm.ClassDate == '' || ruleForm.className == ''"
                :remote-method="getTeacherList"
                @change="classroomChange"
              >
                <el-option value="1" disabled class="border-bottom-grey">
                  <div class="flex justify-content-between" style="color: #000000; font-weight: 600;">
                    <div style="width: 120px;">教室名称</div>
                    <div style="width: 80px;">冲突状态</div>
                    <div style="width: 120px;">时段</div>
                  </div>
                </el-option>
                <el-option v-for="(item, index) in classroomList" :key="index" :label="item.name" :value="item.id" class="border-bottom-grey">
                  <div class="flex justify-content-between">
                    <div style="width: 120px;">{{ item.name }}</div>
                    <div style="width: 80px;">{{ item.status }}</div>
                    <!-- <label style="width: 120px;">查看</label> -->
                  </div>
                </el-option>
              </el-select>

              <el-button style="margin-left: 10px;" type="primary" @click="dialogVisible = true">创建教室</el-button>
            </el-form-item>
            <el-form-item label="助教" prop="TeachingAssistant">
              <el-select v-model="ruleForm.TeachingAssistant" filterable :remote-method="getTeacherList" @change="teachingAssistantChange" placeholder="请选择助教">
                <el-option value="1" disabled class="border-bottom-grey">
                  <div class="flex justify-content-between" style="color: #000000; font-weight: 600;">
                    <div style="width: 120px;">姓名</div>
                    <div style="width: 80px;">性别</div>
                    <div style="width: 120px;">联系方式</div>
                  </div>
                </el-option>
                <el-option v-for="(item, index) in teacherList" :key="index" :label="item.full_name" :value="item.id" class="border-bottom-grey">
                  <div class="flex justify-content-between">
                    <div style="width: 120px;">{{ item.full_name }}</div>
                    <div style="width: 80px;">{{ item.sex == 0 ? '女' : '男' }}</div>
                    <div style="width: 120px;">{{ item.phone }}</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="科目" prop="subject">
              <el-select v-model="ruleForm.subject" placeholder="请选择科目" :disabled="ruleForm.ClassDate == '' || ruleForm.className == ''" @change="subjectChange">
                <el-option v-for="item in SubjectTypeList" :key="item.value" :label="item.title" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上课主题" prop="ClassTheme"><el-input placeholder="最多可输入20字" max="20" v-model="ruleForm.ClassTheme"></el-input></el-form-item>
            <el-form-item><el-button type="primary" @click="submite">确定</el-button></el-form-item>
          </el-form>
        </el-tab-pane>

        <!-- 教师排课 -->
        <el-tab-pane v-if="detail.arrange_type == 1" label="编辑教师排课" name="second">
          <el-form :model="teacherRuleForm" :rules="teacherrules" ref="teacherRuleForm" label-width="150px" class="demo-ruleForm">
            <el-form-item label="上课教师" prop="ClassTeacher">
              <el-select v-model="teacherRuleForm.ClassTeacher" placeholder="请选择上课教师" filterable :remote-method="getTeacherList" @change="teacherChange">
                <el-option value="1" disabled class="border-bottom-grey">
                  <div class="flex justify-content-between" style="color: #000000; font-weight: 600;">
                    <div style="width: 120px;">姓名</div>
                    <div style="width: 80px;">性别</div>
                    <div style="width: 120px;">联系方式</div>
                  </div>
                </el-option>
                <el-option v-for="(item, index) in teacherList" :key="index" :label="item.full_name" :value="item.id" class="border-bottom-grey">
                  <div class="flex justify-content-between">
                    <div style="width: 120px;">{{ item.full_name }}</div>
                    <div style="width: 80px;">{{ item.sex == 0 ? '女' : '男' }}</div>
                    <div style="width: 120px;">{{ item.phone }}</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="排课方式" prop="arrange_mode">
              <el-radio v-model="teacherRuleForm.arrange_mode" label="0">自由排课</el-radio>
              <el-radio v-model="teacherRuleForm.arrange_mode" label="1">重复排课</el-radio>
            </el-form-item>

            <el-form-item label="上课日期" prop="listtime">
              <el-date-picker value-format="yyyy-MM-dd" type="dates" v-model="teacherRuleForm.listtime" placeholder="选择日期" :onPick="dateChange"></el-date-picker>
            </el-form-item>

            <el-form-item label="上课时段" prop="ClassTime">
              <!-- <el-select v-model="teacherRuleForm.ClassTime" placeholder="请选择时间段" :disabled="teacherRuleForm.ClassTeacher == ''" v-if="shows"></el-select> -->

              <el-time-picker
                :disabled="teacherRuleForm.ClassTeacher == ''"
                value-format="HH:mm"
                minTime="07:00"
                maxTime="22:00"
                format="HH:mm"
                is-range
                v-model="teacherRuleForm.ClassTime"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                placeholder="选择时间范围"
                style="width: 220px"
              ></el-time-picker>

              <!-- <label style="margin-left: 10px;" @click="changeTime">{{ text }}</label> -->
            </el-form-item>

            <el-form-item label="班级/一对一" prop="className">
              <el-select
                v-model="teacherRuleForm.className"
                placeholder="请选择班级/一对一"
                :disabled="teacherRuleForm.ClassTeacher == '' || teacherRuleForm.ClassTime == ''"
                :remote-method="getClassList"
                @change="classChange"
              >
                <el-option value="1" disabled class="border-bottom-grey">
                  <div class="flex justify-content-between" style="color: #000000; font-weight: 600;">
                    <div style="width: 290px;">班级名称</div>
                    <div style="width: 80px;">校区</div>
                    <div style="width: 120px;">冲突状态</div>
                  </div>
                </el-option>
                <el-option v-for="(item, index) in classList" :key="index" :label="item.class_name" :value="item.id" class="border-bottom-grey">
                  <div class="flex justify-content-between">
                    <div style="width: 290px;">{{ item.class_name }}</div>
                    <div style="width: 80px;">{{ item.school_id }}</div>
                    <div style="width: 120px;">{{ item.status }}</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>

            <el-form-item label="上课教室" prop="Classroom">
              <el-select
                filterable
                v-model="teacherRuleForm.Classroom"
                placeholder="请选择上课教室"
                :disabled="teacherRuleForm.listtime == '' || teacherRuleForm.className == ''"
                @change="classroomChange"
              >
                <el-option value="1" disabled class="border-bottom-grey">
                  <div class="flex justify-content-between" style="color: #000000; font-weight: 600;">
                    <div style="width: 120px;">教室名称</div>
                    <div style="width: 80px;">冲突状态</div>
                    <div style="width: 120px;">时段</div>
                  </div>
                </el-option>
                <el-option v-for="(item, index) in classroomList" :key="index" :label="item.name" :value="item.id" class="border-bottom-grey">
                  <div class="flex justify-content-between">
                    <div style="width: 120px;">{{ item.name }}</div>
                    <div style="width: 80px;">{{ item.status }}</div>
                    <!-- <label style="width: 120px;">查看</label> -->
                  </div>
                </el-option>
              </el-select>

              <el-button style="margin-left: 10px;" type="primary">创建教室</el-button>
            </el-form-item>
            <el-form-item label="助教" prop="TeachingAssistant">
              <el-select v-model="teacherRuleForm.TeachingAssistant" filterable :remote-method="getTeacherList" @change="teachingAssistantChange" placeholder="请选择助教">
                <el-option value="1" disabled class="border-bottom-grey">
                  <div class="flex justify-content-between" style="color: #000000; font-weight: 600;">
                    <div style="width: 120px;">姓名</div>
                    <div style="width: 80px;">性别</div>
                    <div style="width: 120px;">联系方式</div>
                  </div>
                </el-option>
                <el-option v-for="(item, index) in teacherList" :key="index" :label="item.full_name" :value="item.id" class="border-bottom-grey">
                  <div class="flex justify-content-between">
                    <div style="width: 120px;">{{ item.full_name }}</div>
                    <div style="width: 80px;">{{ item.sex == 0 ? '女' : '男' }}</div>
                    <div style="width: 120px;">{{ item.phone }}</div>
                  </div>
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="科目" prop="subject">
              <el-select
                v-model="teacherRuleForm.subject"
                placeholder="请选择科目"
                @change="subjectChange"
                :disabled="teacherRuleForm.ClassTeacher == '' || teacherRuleForm.className == ''"
              >
                <el-option v-for="item in SubjectTypeList" :key="item.value" :label="item.title" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上课主题" prop="ClassTheme"><el-input placeholder="最多可输入20字" max="20" v-model="teacherRuleForm.arrange_theme"></el-input></el-form-item>
            <el-form-item><el-button type="primary" @click="submite">确定</el-button></el-form-item>
          </el-form>
        </el-tab-pane>
      </el-tabs>
    </div>

    <!-- 新建教室 -->
    <el-dialog title="新建教室" :visible.sync="dialogVisible" :append-to-body="true" :close-on-click-modal="false" :close-on-press-escape="false" width="25%">
      <div>
        <el-form :model="newClassroomForm" :rules="newClassroomRules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="教室名称" prop="name"><el-input v-model="newClassroomForm.name" placeholder="请填写教室名称" /></el-form-item>
          <el-form-item label="校区" prop="school_id">
            <el-select v-model="newClassroomForm.school_id" placeholder="请选择"><el-option label="区域一" value="shanghai"></el-option></el-select>
          </el-form-item>
        </el-form>
        <div class="flex justify-content-flexEnd">
          <el-button type="primary" @click="newClassroom">确定</el-button>
          <el-button class="myClient-left-30" @click="dialogVisible = false">取消</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      classroomForm: {
        arrange_type: 0, // 班级排课 0， 教师排课 1
        arrange_classes: 0, // 上课班级
        arrange_mode: 0,
        listtime: [],
        class_begin_time_start: '',
        class_begin_time_end: '',
        class_room: 0,
        arrange_teacher: 0,
        arrange_assistant: 0,
        arrange_subject: 0,
        arrange_theme: '',
        remark: ''
      },
      activeName: 'first',
      shows: true,
      num: '1',
      text: '自定义时段',
      ruleForm: {
        className: '', //班级/一对一
        schedulingMethod: '0', //排课方式
        ClassTime: '', //上课时段
        ClassDate: [], //上课日期
        ClassTeacher: '', //上课教师
        Classroom: '', //上课教室
        TeachingAssistant: '', //助教
        subject: '', //科目
        ClassTheme: '' //上课主题
      },
      teacherRuleForm: {
        className: '', //班级/一对一
        ClassTime: '', //上课时段
        ClassTeacher: '', //上课教师
        Classroom: '', //上课教室
        TeachingAssistant: '', //助教
        subject: '', //科目

        arrange_type: 1, // 班级排课 0， 教师排课 1
        arrange_classes: 0, // 班级id
        arrange_mode: '0', //排课方式
        listtime: [], //上课日期
        class_begin_time_start: '',
        class_begin_time_end: '',
        class_room: 0, // 教室id
        arrange_teacher: 0, // 教师id
        arrange_assistant: 0, // 助教id
        arrange_subject: 0, // 科目id
        arrange_theme: '' //上课主题
      },
      rules: {
        className: [
          {
            required: true,
            message: '请选择班级/一对一',
            trigger: 'change'
          }
        ],

        ClassDate: [
          {
            required: true,
            message: '请选择上课日期',
            trigger: 'change'
          }
        ],
        ClassTime: [
          {
            required: true,
            message: '请选择上课时段',
            trigger: 'change'
          }
        ],
        ClassTeacher: [
          {
            required: true,
            message: '请选择上课教师',
            trigger: 'change'
          }
        ],
        Classroom: [
          {
            required: true,
            message: '请选择上课教室',
            trigger: 'change'
          }
        ]
      },
      teacherrules: {
        className: [
          {
            required: true,
            message: '请选择班级/一对一',
            trigger: 'change'
          }
        ],

        listtime: [
          {
            required: true,
            message: '请选择上课日期',
            trigger: 'change'
          }
        ],
        ClassTime: [
          {
            required: true,
            message: '请选择上课时段',
            trigger: 'change'
          }
        ],
        ClassTeacher: [
          {
            required: true,
            message: '请选择上课教师',
            trigger: 'change'
          }
        ],
        Classroom: [
          {
            required: true,
            message: '请选择上课教室',
            trigger: 'change'
          }
        ]
      },
      classList: [],
      classQueryData: {
        KeyWord: '',
        PageSize: 20,
        PageIndex: 1
      },
      teacherList: [],
      teacherQueryData: {
        post_tag: ['教师'],
        KeyWord: '',
        PageSize: 20,
        PageIndex: 1
      },
      classroomList: [],
      classroomQueryData: {
        KeyWord: '',
        PageSize: 20,
        PageIndex: 1
      },
      curriculumList: [],
      isShowArrangeTeacher: true, //显示教师排课
      classDisabled: false, // 控制选择班级
      newClassroomForm: {
        name: '',
        school_id: 3
      }, // 新建教室
      newClassroomRules: {
        name: [{ required: true, message: '请输入活动名称', trigger: 'blur' }],
        school_id: [{ required: true, message: '请选择校区', trigger: 'change' }]
      },
      dialogVisible: false, // 显示新建教室
      SubjectTypeList: [],
      detail: {},
      listQuery1: {
        HasRole: false,
        module: '',
        PageIndex: 1,
        PageSize: 15
      },
    };
  },
  created() {
    this.getClassList();
    this.getTeacherList();
    this.getClassroomList();
    this.getSubjectTypeList();
    if (this.$route.query.id) {
      let obj = this.$route.query;
      this.classroomForm.arrange_classes = Number(obj.classid);
	  this.getDetail()
      this.classDisabled = true;
    }
  },
  methods: {
    getDetail() {
      this.API.CurriculumarrangerecordsDetail(this.$route.query.id).then(res => {
        this.detail = res.data
		this.ruleForm.className=res.data.arrange_classes_name
		this.ruleForm.schedulingMethod=String(res.data.arrange_mode)
		this.ruleForm.ClassTime=`${res.data.class_begin_time_start}-${res.data.class_begin_time_end}`
		this.ruleForm.ClassDate=res.data.class_begin_date 
		this.ruleForm.ClassTeacher=res.data.arrange_teacher_name 
		this.ruleForm.Classroom=res.data.class_room_name
		this.ruleForm.TeachingAssistant=res.data.arrange_assistant_name 
		this.ruleForm.subject=res.data.arrange_subject_name 
		this.ruleForm.ClassTheme=res.data.arrange_theme
		
		this.classroomForm.id=Number(this.$route.query.id)
		this.classroomForm.arrange_type=res.data.arrange_type
		this.classroomForm.arrange_mode=res.data.arrange_mode
		this.classroomForm.class_room=res.data.class_room
		this.classroomForm.arrange_teacher=res.data.arrange_teacher
		this.classroomForm.arrange_assistant=res.data.arrange_assistant
		this.classroomForm.arrange_subject =res.data.arrange_subject;
        if(this.detail.arrange_type === 1) this.activeName = 'second'
        
      })
    },
    dateChange(e) {},
    // 获取班级列表
    getClassList() {
      this.API.getClassList(this.classQueryData).then(res => {
        this.classList = res.data.rows;
      });
    },
    // 选择班级
    classChange(e) {
      let index = this.classList.findIndex(t => t.id == e);
      if (this.activeName == 'first') {
        this.ruleForm.className = this.classList[index].class_name;
        this.classroomForm.arrange_classes = e;
        if (this.classList[index].curriculum) this.curriculumList.push(this.classList[index].curriculum);
        console.log(this.classList[index]);
        this.$forceUpdate();
      } else {
        this.teacherRuleForm.className = this.classList[index].class_name;
        this.teacherRuleForm.arrange_classes = e;
        if (this.classList[index].curriculum) this.curriculumList.push(this.classList[index].curriculum);
      }
    },
    changeTime() {
      this.num++;
      if (this.num % 2 == 0) {
        this.shows = true;
      } else {
        this.text = '取消自定义时段';
        this.shows = false;
      }
    },
    // 获取教师列表
    getTeacherList() {
      this.API.accountQuery(this.teacherQueryData).then(res => {
        this.teacherList = res.data.rows;
      });
    },
    // 选择教师
    teacherChange(e) {
      let index = this.teacherList.findIndex(t => t.id == e);
      if (this.activeName == 'first') {
        this.ruleForm.ClassTeacher = this.teacherList[index].full_name;
        this.classroomForm.arrange_teacher = e;
      } else {
        this.teacherRuleForm.ClassTeacher = this.teacherList[index].full_name;
        this.teacherRuleForm.arrange_teacher = e;
      }
    },
    // 选择助教
    teachingAssistantChange(e) {
      const index = this.teacherList.findIndex(t => t.id == e);
      if (this.activeName == 'first') {
        this.ruleForm.TeachingAssistant = this.teacherList[index].full_name;
        this.classroomForm.arrange_assistant = e;
        // this.classroomForm.arrange_teacher = e.id
      } else {
        this.teacherRuleForm.TeachingAssistant = this.teacherList[index].full_name;
        this.teacherRuleForm.arrange_assistant = e;
        // this.teacherRuleForm.arrange_teacher = e.id
      }
    },
    // 获取教室列表
    getClassroomList() {
      this.API.getClassroomList(this.classroomQueryData).then(res => {
        this.classroomList = res.data.rows;
      });
    },
    // 选择教室
    classroomChange(e) {
      const index = this.classroomList.findIndex(t => t.id == e);
      console.log(e);
      if (e) {
        if (this.activeName == 'first') {
          this.ruleForm.Classroom = this.classroomList[index].name;
          this.classroomForm.class_room = e;
        } else {
          this.teacherRuleForm.Classroom = this.classroomList[index].name;
          this.teacherRuleForm.class_room = e;
        }
      }
    },
    // // 获取科目
    // getSubjectTypeList() {
    //   this.listQuery1.module = 'SubjectType';
    //   this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
    //     this.SubjectTypeList = res.data.rows;
    //     this.$forceUpdate();
    //   });
    // },
	// 获取科目
	getSubjectTypeList() {
		let total = 0
		this.getMoreSubjectTypeList().then(res => {
			let total = Math.ceil(res.data.total / this.listQuery1.PageSize)
			if (total == 0) {
				this.listQuery1.PageIndex = 1
			} else if (this.listQuery1.PageIndex < total) {
				this.listQuery1.PageIndex++
			}
			this.getMoreSubjectTypeList().then(val => {
				this.SubjectTypeList = res.data.rows.concat(val.data.rows)
			})
			this.$forceUpdate();
		})
	},
	getMoreSubjectTypeList() {
		return new Promise((resolve, reject) => {
			this.listQuery1.module = 'SubjectType';
			this.$Dictionary.GetDictionarys(this.listQuery1).then(res => {
				resolve(res)
			});
		})
	},
    // 新建教室
    newClassroom() {
      this.dialogVisible = true;
      this.API.addClassrooms(this.newClassroomForm).then(res => {
        if (res.code == 200) {
          this.$message.success(res.message);
          this.dialogVisible = false;
        } else {
          this.$message.error(res.message);
          this.dialogVisible = false;
        }
      });
    },
    // 选择科目
    subjectChange(e) {
      const index = this.SubjectTypeList.findIndex(t => t.id == e);
      if (this.activeName == 'first') {
        this.classroomForm.subject = this.SubjectTypeList[index].title;
        this.classroomForm.arrange_subject = e;
      } else {
        this.teacherRuleForm.subject = this.SubjectTypeList[index].title;
        this.teacherRuleForm.arrange_subject = e;
      }
    },
    submite() {
      let obj;
      if (this.activeName == 'first') {
        obj = this.classroomForm;
        let form = this.ruleForm;
		let arr=[]
		arr.push(form.ClassDate)
		if(typeof(form.ClassTime)=='string'){
			form.ClassTime=form.ClassTime.split('-')
		}
		console.log(arr)
        obj.listtime = arr;
        obj.arrange_mode = form.schedulingMethod * 1;
        obj.class_begin_time_start = form.ClassTime[0];
        obj.class_begin_time_end = form.ClassTime[1];
        obj.arrange_theme = form.ClassTheme;
      } else {
        obj = this.teacherRuleForm;
        obj.arrange_mode = obj.arrange_mode * 1;
        obj.class_begin_time_start = obj.ClassTime[0];
        obj.class_begin_time_end = obj.ClassTime[1];
      }
      console.log(JSON.stringify(obj));
      this.API.arrangeCurriculum(obj).then(res => {
        if (res.success) {
          this.$message.success(res.message);
          this.$router.go(-1);
        } else {
          this.$message.error(res.message);
        }
      });
    }
  }
};
</script>

<style></style>
